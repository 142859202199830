import "@styles/app.sass"
import Swiper, {Navigation, EffectCoverflow} from "swiper";
import lightGallery from "lightgallery";
import {countUpNumber} from "./utils/countUp";

(() => { // Hiding header
    const header = document.querySelector('[data-page-header]') as HTMLElement;
    const headerOffset = header.offsetTop - window.scrollY,
        headerHeight = (header.children[0] as HTMLElement)?.offsetHeight ?? 0;

    let lastScroll = 0;

    const hidingOnScroll = () => {
        const currentScroll = window.scrollY;

        if (currentScroll > headerOffset + headerHeight) {
            header.classList.add('is-sticked')
            header.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
            document.body.classList[currentScroll > lastScroll ? 'remove' : 'add']('is-sticked-header')
        } else {
            header.classList.remove('is-sticked')
            header.classList.remove('is-up')
            document.body.classList.remove('is-sticked-header')
        }

        lastScroll = currentScroll;
    }

    hidingOnScroll();
    window.addEventListener('scroll', () => hidingOnScroll(), { passive: true });

    const navOpener = document.querySelector('[data-nav-opener]')
    const toggleNav = () => {
        document.body.classList.toggle('u-hiddenOverflow');
        navOpener.closest('[data-page-header]')?.classList.toggle('is-opened');
    }

    navOpener.addEventListener("click", toggleNav)
    header.querySelectorAll('a[href^="#"]').forEach(link => link.addEventListener("click", toggleNav))
})();

(() => { // home hero swiper
    const slider = document.querySelector('[data-testimonial-swiper]') as HTMLElement;
    if (!slider) return;

    new Swiper(slider, {
        slideClass: 'TestimonialPost',
        slideActiveClass: 'is-active',
        modules: [ Navigation ],
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            1024: {
                slidesPerView: "auto",
                spaceBetween: 0
            }
        },
        navigation: {
            nextEl: '[data-testimonial-next]',
            prevEl: '[data-testimonial-prev]',
        },
        ...(window.innerWidth > 1024 && !document.body.classList.contains('is-mobile') ? {
            modules: [ Navigation, EffectCoverflow ],
            centeredSlides: true,
            effect: "coverflow",
            coverflowEffect: {
                depth: 0,
                rotate: 0,
                scale: .8,
                slideShadows: false
            }
        } : { cssMode: true })
    });
})();

(() => { // pageContent gallery
    const galleries = document.querySelectorAll('.wp-block-gallery');
    galleries.forEach((gallery: HTMLElement) => lightGallery(gallery, {
        selector: 'figure > a',
        speed: 400,
        download: false,
        getCaptionFromTitleOrAlt: false,
        mobileSettings: {
            showCloseIcon: true
        }
    }));
})();

(() => { // CountUp homepage numbers
    const callback = function(entries, observer) {
        entries.forEach((entry) => {
            entry.isIntersecting && countUpNumber(entry.target)
        })
    }

    const observer = new IntersectionObserver(callback, { root: null, threshold: 0.5 })
    const numbers = document.querySelectorAll('[data-number]')
    numbers.forEach(number => {
        number.innerHTML = parseInt(number.innerHTML).toLocaleString()
        observer.observe(number)
    })
})();